import React, {useState,useEffect, useReducer} from 'react';
import {
    Container,Alert,
  Table,
  Col,
  Row,
  Button,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Spinner,
  OverlayTrigger,Tooltip
  } from 'react-bootstrap'
  import {
    _URL_INSTRUMENT,
    PostHttp,
    DeleteHttp,
    GetHttpParams,PutHttp,
    _URL_INSTRUMENT_INHAND,  
  } from '../../assets/js/Connectorv2';
  import {
    AccountAvatar    
  } from "../../component/components.js"
  
  import * as templates from "../../assets/js/UITemplates";
  import { BasicTable } from '../../component/table';
import { Comments } from './instrumentComments';
import ImageSlider from '../../component/imageSlider.js';
import { PageLoadMain } from '../../component/loader.js';

  export function InstrumentEditor(props) {

    let [readOnly, setReadOnly] = useState(true);
    let [showLoader, setShowLoader] = useState(true);
    let [type, setType] = useState([]);
    let [tab, setTab] = useState(0);
    const [images,setImages] = useState([]);
    const [errorMessage,setErrorMessage] = useState('')
    
    
    function reducer (state,act)  {
      if(act.id!=='init'){
        if(act.id==="cost"){
          if(!isNaN(act.val)){
            state[act.id]=act.val
            return {...state};
          }
        }else {
          state[act.id]=act.val
          return {...state};
        }
        return state;
      }else{
        return act.val;
      }

    }
    const [instrument,setInstrument] = useReducer(reducer,{
      name: '',
      model: '',
      vendor: '',
      type: '',
      serial: '',
      invent: '',
      status: '',
      cost: '0',
      warrantyString: null,
      purchaseString: null,
      singleIssue:false
    });
    function fetch(){
      GetHttpParams(_URL_INSTRUMENT, {
        instrument: props.id
      }).then((int) => {
        setInstrument({id:'init',val:{
          id:int.id,
          name: int.name,
          model: int.model,
          vendor: int.vendor,
          type: int.type,
          serial: int.serial,
          invent: int.invent,
          status: int.status,
          cost: int.cost,
          warrantyDate:int.warrantyDate,
          purchaseDate:int.purchaseDate,
          warrantyString: int.warrantyString,
          purchaseString: int.purchaseString,
          singleIssue:false,
          comments:int.comments,
          instrumentCounters: int.instrumentCounters,
          totalСount:int.totalСount
        }});
        setShowLoader(false)
        
      })
    }
    function fetchImages(id){
      GetHttpParams(_URL_INSTRUMENT+"/resourses",{instrument:id})
      .then(res=>{
        const img = res.sort(s=>!s.defaultFile);
        setImages(res);
      });
    }
    useEffect(() => {
      fetch();
      fetchImages(props.id);
      GetHttpParams(_URL_INSTRUMENT + '/types')
        .then(e => {
          setType(e);
        })
  
    }, [props.id])
  
    function setEditable() {
      if (!readOnly) {
        PutHttp(instrument, _URL_INSTRUMENT).catch(err=>{
          setErrorMessage(err.statusText);
        });
      }
      setReadOnly(!readOnly);
  
    }
    
    const toggleSingleIssue = () =>{
      if(!readOnly){
        setInstrument({id:'singleIssue',val: !instrument.singleIssue});
        
      }
    }

    function addCode() {
      if(instrument.invent!== "" ||   instrument.invent.length>0){
      PostHttp({
        instrument: instrument.id,
        code: instrument.invent
      }, _URL_INSTRUMENT + '/code')
        .then(() => {
          GetHttpParams(_URL_INSTRUMENT + '/code', {
            instrument: instrument.id
          }).then((i) => {
              
            })

        }).catch(er=>setErrorMessage(er.data.reason))
      }
    }

    let content;
    let tabContent;
  
    switch (tab) {
      case 0:
        tabContent = <div id="inhand" className='border mt-3 p-2 shadow' style={{ backgroundColor: templates.block_background_color, borderRadius: "10px", }}>
          <div>
            {instrument.id !== undefined ? <InhandView instrument={instrument} /> : <Spinner />}
          </div>
        </div>
        break;
      case 1:
        tabContent =
          <div id="history" className='border mt-3 p-2 shadow' style={{ backgroundColor: templates.block_background_color, borderRadius: "10px", }}>
            <div>
              {instrument.id !== undefined ? <IssueHistory instrument={instrument} /> : <Spinner />}
            </div>
          </div>
        break;
      case 2:
        tabContent = <div id="stat" className='border mt-3 p-2 shadow' style={{ backgroundColor: templates.block_background_color, borderRadius: "10px", }}>
          <div>
            {instrument.id !== undefined ? <StasticView instrument={instrument} /> : <Spinner />}
          </div>
        </div>
        break;
      case 3:
        tabContent = <div id="codes" className='border mt-3 p-2 shadow' style={{ backgroundColor: templates.block_background_color, borderRadius: "10px", }}>
          {instrument.id !== undefined ? <InstrumentEditCodes instrument={instrument.id} onError={(e)=>setErrorMessage(e)}/> : <Spinner />}
        </div>
        break;
      case 4:
        tabContent = <div id="check"><InstrumentCheck handleChange={(event)=>setInstrument(event)} instrument={instrument} readOnly={readOnly}/></div>
        break
      case 5: 
        tabContent = <div id="foto"><ImageSlider images={images} instrumentid={instrument.id} update={()=>fetchImages(props.id)}/></div>
        break;
      default:
        break;
    }
    const wDate = new Date(instrument.warrantyDate);
    const pDate = new Date(instrument.purchaseDate);
    
    content =
      <>
     
      {errorMessage!==''? <Alert dismissible variant="danger" onClose={()=>setErrorMessage('')}>
              {errorMessage}
            </Alert> :''}
        <div className="d-flex mt-1">
          <div className="flex-fill me-2 p-1">
            <div className='border shadow' style={{ backgroundColor: templates.block_background_color, borderRadius: "10px", }}>
                <div className="text-center p-1"><h4>Общие</h4></div>
                <div className="d-flex p-2">
                  <div className="m-2 p-1 mb-5 border" style={{ maxWidth: "300px" }}>
                    <AccountAvatar images={images}/>
                  </div>
                  <div className="mx-1 p-1">
                    <Form.Group >
                    <Form.Label className='text-muted'>Наименование</Form.Label>
                    <EditableForm name="name" readOnly={readOnly} value={instrument.name} onChange={(e)=>setInstrument({id:'name',val:e.target.value})}/>
                    </Form.Group>
                    <Form.Group className='mt-1' >
                      <Form.Label  className='text-muted'>Поизводитель</Form.Label>
                      <EditableForm name="vendor" readOnly={readOnly} value={instrument.vendor} onChange={(e)=>setInstrument({id:'vendor',val:e.target.value})}/>
                    </Form.Group>
                    <Form.Group className='mt-1'>
                      <Form.Label  className='text-muted'>Серийный №</Form.Label>
                      <EditableForm name="serial" readOnly={readOnly} value={instrument.serial} onChange={(e)=>setInstrument({id:'serial',val:e.target.value})}/>
                    </Form.Group>
                    <div>
                    <Form.Check disabled={readOnly} className='m-3' type="switch" id="custom-switch" label="Единичная выдача" onChange={toggleSingleIssue} defaultChecked={instrument.singleIssue} />                      
                    </div>
                  </div>
                  <div className='mx-1 p-1'>
                    <Form.Group  >
                      <Form.Label  className='text-muted'>Модель</Form.Label>
                      <EditableForm name="model" readOnly={readOnly} value={instrument.model} onChange={(e)=>setInstrument({id:'model',val:e.target.value})}/>
                    </Form.Group>
                    <Form.Group className='mt-1' >
                      <Form.Label  className='text-muted'>Тип</Form.Label>
                      <EditableFormList name="type" readOnly={readOnly} value={instrument.type} onChange={(e)=>setInstrument({id:'type',val:e.target.value})} list='combo-options'/>
                      <datalist id="combo-options">
                        {type !== undefined ? type.map(v => {
                          return (<option>{v}</option>)
                        }) : ''}
                      </datalist>
                    </Form.Group>
                    <Form.Group className='mt-1' >
                      <Form.Label  className='text-muted'>Инвентарный</Form.Label>
                      <div className='d-flex'>
                      <div><EditableForm 
                        name="invent" 
                        readOnly={readOnly} 
                        value={instrument.invent} 
                        onChange={(e)=>setInstrument({id:'invent',val:e.target.value})}/></div>
                        {!readOnly ?
                        <OverlayTrigger placement="top"
                        overlay={
                          <Tooltip>
                          Добавить номер в список штрих-кодов
                        </Tooltip>
                        }>
                          <Button variant="outline-primary" className='ms-3' size="sm" onClick={()=>addCode()}><i className=" m-0 fs-5 bi bi-qr-code"></i></Button>
                        </OverlayTrigger>
                        :''}
                        
                        </div>
                    </Form.Group>
                  </div>
  
                </div>
              </div>
                <div>
                  <div className="d-flex align-items-end p-2 border shadow my-3" style={{ backgroundColor: templates.block_background_color, borderRadius: "10px", }}>
                    <Form.Group className="m-1 p-1" >
                      <Form.Label className='text-muted my-2'>Гарантия до</Form.Label>
                      <EditableFormDate 
                        name="warranty" 
                        onChange={(e)=>setInstrument({id:'warrantyString',val:e.target.value})} 
                        readOnly={readOnly} 
                        value={instrument.warrantyDate !== null ? wDate.getFullYear().toString().padStart(4, '0') + '-' + (wDate.getMonth() + 1).toString().padStart(2, '0') + '-' + wDate.getDate().toString().padStart(2, '0') : ''}/>
                    </Form.Group>
                    <Form.Group className="m-1 p-1" >
                      <Form.Label className='text-muted my-2' >Дата приобретения</Form.Label>
                      <EditableFormDate 
                        name="purchase" 
                        onChange={(e)=>setInstrument({id:'purchase',val:e.target.value})} 
                        readOnly={readOnly} 
                        value={instrument.purchaseDate !== null ? pDate.getFullYear().toString().padStart(4, '0') + '-' + (pDate.getMonth() + 1).toString().padStart(2, '0') + '-' + pDate.getDate().toString().padStart(2, '0') : ''}/>
                    </Form.Group>
                    <Form.Group className="m-1 p-1">
                      <Form.Label className='text-muted my-2'>Состояние</Form.Label>
                      <EditableForm
                        name="status" 
                        onChange={(e)=>setInstrument({id:'status',val:e.target.value})} 
                        readOnly={readOnly} 
                        value={instrument.status===""?"-":instrument.status}/>                     
                    </Form.Group>
                    <Form.Group className="m-1 p-1">
                      <Form.Label className='text-muted my-2'>Стоимость</Form.Label>
                      <EditableForm 
                        name="cost"
                        onChange={(e)=>setInstrument({id:'cost',val:e.target.value})} 
                        readOnly={readOnly} 
                        value={instrument.cost}/>  
                      
                    </Form.Group>
                  </div>
                </div>                
                {tabContent}
                <div className="styled-block my-2 border shadow p-1" >
                  <Comments instrument={instrument}/>
                </div>
              
          </div>
          <div>
            <ButtonPanelInstrumentEditor
              readOnly={readOnly}
              enableEdit={() => setEditable()}
              setContent={props.onClose}
              setTab={(tab) => setTab(tab)}
            />
          </div>
        </div>
      </>
  
    if(showLoader){
      return <PageLoadMain />
    }else {
    return (
  
      // <StasticView/>
      <>
        <div className="content-pane dynamic-content dynamic-content-md">{content}</div>
      </>
    )}
  }

  function EditableForm({name,value,readOnly,onChange}){

    if(readOnly){
      return ( <div className='mb-2 fw-bold'>{value}</div>)
    }else {
      return(
        <Form.Control id={name} type="text" onChange={onChange}
        defaultValue={value}/>
      )
    }
  }

  function EditableFormList({name,value,readOnly,onChange,list}){

    if(readOnly){
      return ( <div className='mb-2 fw-bold'>{value}</div>)
    }else {
      return(
        <Form.Control id={name} type="text" onChange={onChange}
        defaultValue={value} list={list}/>
      )
    }
  }

  function EditableFormDate({name,value,readOnly,onChange}){

    if(readOnly){
      return ( <div className='mb-2 fw-bold'>{value===""?"Не установленна" :value}</div>)
    }
    return(
      <Form.Control id={name} type="date" onChange={onChange} readOnly={readOnly} defaultValue={value} />
    )
  }

  function InstrumentEditCodes({onError,instrument}){
    const [showAddCode,setShowAddCode] = useState(false);
    const [codes,setCodes] = useState([]);
    const [newCode,setNewCode] = useState();

    function fetch(){
      GetHttpParams(_URL_INSTRUMENT + '/code', {
        instrument: instrument
      }).then((i) => {setCodes(i)})
    }

    useEffect(()=>fetch(),[])

    const handleDeleteCode = (id) => {
      DeleteHttp(_URL_INSTRUMENT + '/code', {
        id: id
      }).then(() => {
        fetch()
      }).catch((error)=> onError(error.data.reason));
    }

    function handleAddcode() {
      PostHttp({
        instrument: instrument,
        code: newCode
      }, _URL_INSTRUMENT + '/code')
        .then(() => {
          fetch();
          setShowAddCode(false);
        }).catch((error)=> {onError(error.data.reason);setShowAddCode(false)});
    }

    return (
      <>
        
        <Modal show={showAddCode} onHide={()=>setShowAddCode(false)}>
        <Modal.Header>
          <Modal.Title>Добавить штрих-код</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="code">Штрих-код</InputGroup.Text>
            <FormControl
              onChange={(e)=>setNewCode(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowAddCode(false)}>
            Закрыть
          </Button>
          <Button variant="primary" onClick={()=>handleAddcode()}>
            Добавить
          </Button>
        </Modal.Footer>
      </Modal>

        <Row className="text-center p-1"><h4>Штрих-коды</h4></Row>
        <Row className="">

          <Table striped bordered hover size="sm">
            <thead >
              <tr style={{ textAlign: "center" }}>
                <th>#</th>
                <th>Код</th>
                <th>Удалить</th>

              </tr>
            </thead>
            <tbody>
              {codes.map((v, k) => {
                return (<tr style={{ textAlign: "center" }}>
                  <td>{k + 1}</td>
                  <td>{v.code}</td>
                  <td className="w-25"><Button variant="danger" onClick={() => handleDeleteCode(v.id)}>X</Button></td>
                </tr>)
              })}

            </tbody>
          </Table>
        </Row>

        <Row className="justify-content-md-center m-2">
          <Button onClick={()=>setShowAddCode(true)}>{templates.button_create}</Button>
        </Row>
      </>
    )

  }

  
  
  export function IssueHistory(props)  {
  
    const [instrumentTransfer,setInstrumentTransfer]=useState({content: []})
  
    function fetch(pageParams){
      GetHttpParams(_URL_INSTRUMENT + '/history', {
        instrument: props.instrument.id,
        page: pageParams[0],sort:pageParams[1]
      }).then((res) => {
          setInstrumentTransfer(res);
      })
    }
  
    
      const table = instrumentTransfer.content.map((val, key) => {
  
        let action = "Выдано";
        if (val.instrumentTransferAction === "return_inst") {
          action = "Возврат"
        }
  
        return (<tr key={key}>
          <td>{key + 1}</td>
          <td>{val.recipient.firstname}</td>
          <td>{val.recipient.surname}</td>
          <td>{val.count}</td>
          <td>{action}</td>
          <td>{new Date(val.timestamp).toLocaleDateString()}</td>
  
        </tr>)
      })
  
      const tableField = [{name:"#",sort:"id"},{name:"Имя",sort:"recipient_firstname"},
        {name:"Фамилия",sort:"recipient_surname"},{name:"Количество",sort:"count"},{name:"Дейстие",sort:""},{name:"Дата",sort:"timestamp"}]
      return (<>
        <BasicTable fields={tableField} page={instrumentTransfer} onsort={(e)=>fetch(e)}>
            {table}
        </BasicTable>
        </>)
    
  }
  
  export function InhandView(props) {
  
  
    const [instInHand,setInstInHand] = useState({content: []});
  
    function fetch(pageParams) {
      GetHttpParams(_URL_INSTRUMENT_INHAND, {
        instrument: props.instrument.id,
        page: pageParams[0],sort:pageParams[1]
      })
        .then(r => {
          setInstInHand(r);
        })
    }
    const tableField = [{name:"#",sort:"id"},{name:"Имя",sort:"recipient_firstname"},
    {name:"Фамилия",sort:"recipient_surname"},{name:"Количество",sort:"count"},{name:"Дата",sort:"timestamp"}]
      const table = instInHand.content.map((val, key) => {
        return (<tr key={key}>
          <td>{key + 1}</td>
          <td>{val.recipient.firstname}</td>
          <td>{val.recipient.surname}</td>
          <td>{val.count}</td>
          <td>{new Date(val.timestamp).toLocaleDateString()}</td>
  
        </tr>)
      })
      return (<>
          <div className="text-center p-1"><h4>На руках</h4></div>
          
            <BasicTable fields={tableField} page={instInHand} onsort={(e)=>fetch(e)} >
              {table}
            </BasicTable>
  
        
        </>)
    
  }
  
  class StasticView extends React.Component {
  
    render() {
      let table;
      if (this.props.instrument.instrumentCounters != null) {
        const total = this.props.instrument.totalСount;
        const avalable = this.props.instrument.instrumentCounters.avalable;
        const reserved = this.props.instrument.instrumentCounters.reserved;
  
        table =
          <>
            <tr>
              <td>1</td>
              <td>Общее кол-во</td>
              <td>{total}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Осталось на складе</td>
              <td>{avalable}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Зарезервировано</td>
              <td>{reserved}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Выдано и на других обьектах</td>
              <td>{total - avalable - reserved}</td>
            </tr>
  
          </>
      } else {
        table = <><h3> Инструмент не поступал на склад </h3></>
      }
      return (
        <Container >
          <Row className="text-center p-1"><h4>Статистика</h4></Row>
          <Row>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Параметр </th>
                  <th>Значение</th>
  
                </tr>
              </thead>
              <tbody>
                {table}
              </tbody>
            </Table>
          </Row>
        </Container>
      )
    }
  }
  function InstrumentCheck(props) {
    const [enable,setEnable] =useState(props.instrument.checkEnable)
  
    const enableState = (event) => {
      setEnable(event.target.checked);
      props.instrument.checkEnable=event.target.checked;
    }
  
    const pDate = new Date(props.instrument.checkDate);
    return <>
      <div className='styled-block p-1 mt-2 border shadow d-block'>
        <div>
          <Form.Group className="mb-3">
            <Form.Check 
              type="checkbox"
              id="enableCheck"
              label="Поверка инстурмента"
              onChange={enableState}
              disabled={props.readOnly}
              checked={enable}/>
          </Form.Group>
        </div>
        <div className='d-flex'>
          <div className="me-2">
            <Form.Group as={Col} >
              <Form.Label>Дата поверки</Form.Label>
              <Form.Control id="dateCheck" type="date" 
              onChange={(e)=>props.handleChange({id:'checkDateString',val:e.target.value})}
              readOnly={!enable}
              disabled={props.readOnly}
              defaultValue={props.instrument.checkDate !== null ? pDate.getFullYear().toString().padStart(4, '0') + '-' + (pDate.getMonth() + 1).toString().padStart(2, '0') + '-' + pDate.getDate().toString().padStart(2, '0') : ''}
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group as={Col} >
              <Form.Label>Интервал мес.</Form.Label>
              <Form.Control id="intervalCheck" type="number" 
                onChange={(e)=>props.handleChange({id:'checkInterval',val:e.target.value})} 
                defaultValue={props.instrument.checkInterval}
                readOnly={!enable}
                disabled={props.readOnly}
              />
            </Form.Group>
          </div>
        </div>
      </div>
  
    </>
  }

  function ButtonPanelInstrumentEditor(props) {

    return (<>
      <div className="border border-success rounded p-2 text-nowrap  mx-2" style={{ backgroundColor: templates.block_background_color }}>
        <div className="d-block">
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={() => props.setTab(0)}><i className="bi bi-minecart-loaded me-1"></i>{templates.button_instrument_inhand} </Button>
          </div>
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={() => props.setTab(1)}><i className="bi bi-bar-chart me-1"></i>{templates.button_issue_history} </Button>
          </div>
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={() => props.setTab(2)}><i className="bi bi-graph-up me-1"></i>{templates.button_instrument_stat} </Button>
          </div>
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={() => props.setTab(3)}><i className="bi bi-upc me-1"></i>{templates.button_codes} </Button>
          </div>
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={() => props.setTab(4)}><i className="bi bi-activity me-1"></i>{templates.button_instrument_check} </Button>
          </div>
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={props.setContent}><i className="bi bi-arrow-left-square me-1"></i>{templates.button_back} </Button>
          </div>
          <div className='p-1'>
            <Button variant="warning" className="w-100" onClick={props.enableEdit}><i className="bi bi-pen me-1"></i>{props.readOnly ? templates.button_enableEdit : templates.button_save} </Button>
          </div>
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={() => props.setTab(5)}><i className="bi bi-pen me-1"></i>Фото </Button>
          </div>
  
        </div>
  
        {/* <div class="text-nowrap " style={{ backgroundColor: templates.block_background_color }}>
          <div className="d-block">
            <div className='p-1'>
              <Button variant="success" className="w-100" onClick={() => document.location.href = 'instrument#codes'}>{templates.button_save_inhand} </Button>
            </div>
          </div>
        </div> */}
      </div>
    </>)
  }
  
  export function ButtonPanelInstrumentAdd(props) {
  
    return (<>
      <div class=" border border-success rounded p-2" style={{ backgroundColor: templates.block_background_color, width: '230px' }}>
        <div className="d-block">
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={props.onSave}><i className="bi bi-arrow-left-square me-1"></i>{templates.button_create} </Button>
          </div>
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={() => props.setContent(1)}><i className="bi bi-arrow-left-square me-1"></i>{templates.button_back} </Button>
          </div>
        </div>
      </div>
  
    </>)
  }